import Vue from 'vue'
import BootstrapVue from "bootstrap-vue"
import VueMoment from "vue-moment"
import TourDescription from './components/TourDescription.vue'
import TourInfo from './components/TourInfo.vue'
import DataFilter from './components/DataFilter.vue'
import Fa from '@hc/ui/util/components/Fa.vue'
// styles & global scripts
import 'bootstrap/dist/js/bootstrap.bundle'
import './styles/main.sass'
import './styles/_fonts.sass'
import '@hc/ui/fontawesome'

// vue configuration
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueMoment)

// global components
Vue.component('TourDescription', TourDescription)
Vue.component('TourInfo', TourInfo)
Vue.component('DataFilter', DataFilter)
Vue.component('Fa', Fa)
